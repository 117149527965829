import Mock from 'mockjs';
var data = Mock.mock({
  'items|30': [{
    id: '@id',
    title: '@sentence(10, 20)',
    'status|1': ['published', 'draft', 'deleted'],
    author: 'name',
    display_time: '@datetime',
    pageviews: '@integer(300, 5000)'
  }]
});
export default [{
  url: '/table/list',
  type: 'get',
  response: function response(config) {
    var items = data.items;
    return {
      code: 20000,
      data: {
        total: items.length,
        items: items
      }
    };
  }
}];